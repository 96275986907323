import React from 'react';
import { Link } from 'react-router-dom';
import error from '../assets/images/error.svg';

const Error = (): JSX.Element => {
  return (
    <div className='error-page'>
      <div className='error-container'>
        <div className='error-elements'>
          <img src={error} alt='warning triangle, error' className='error-triangle' />
          <p>Jokin meni vikaan.</p>
          <p>
            <b>
              <Link to='/' className='error-link'>
                Siirry takaisin etusivulle.
              </Link>
            </b>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Error;
