import axios from 'axios';
import env from '../config';

import { 
  MedResponse,
  OrgResponse,
  SpcResponse,
  MedOrgNameResponse,
  SearchTermsResponse,
  DailyVisitsResponse,
  OrgType,
  FrontpageInsightsResponse,
  NotificationSettingsResponse,
  ADGroupResponse
} from '../types/types';

const instance = axios.create({
  baseURL: `${env.ApiBaseUrl}`,
  headers: {
    authorization: `bearer ${localStorage.getItem('token')}`,
  },
});

const axiosGet = async (url: string) => {
  try {
    const response = await instance.get(url);
    return response.data;
  } catch (error) {
    console.log({ error });
  }
};

// LOGIN

const loginWithCode = async (code: string): Promise<never> => {
  return await axiosGet(`${env.ApiBaseUrl}/loginwithcode?code=${code}`);
};

const login = async (): Promise<never> => {
  return await axiosGet(`${env.ApiBaseUrl}/login`);
};

// MEDS
const getMedNames = async (page: string): Promise<MedOrgNameResponse> => {
  try {
    return await axiosGet(`${env.ApiBaseUrl}/listmeds?page=${page}`);
  } catch (error){
    console.log({error});
  }
};

const getMeds = async (
    meds: string[],
    startDate?: Date,
    endDate?: Date): Promise<MedResponse> => {
  try {
    const response: any = await instance.post(`${env.ApiBaseUrl}/medquery`, { meds, startDate, endDate });
    return response.data;
  } catch (error) {
    console.log({ error });
  }
};

// ORGS BY MEDS
const getOrgNames = async (): Promise<MedOrgNameResponse> => {
  try {
    return await axiosGet(`${env.ApiBaseUrl}/listorgs`);
  } catch (error){
    console.log({error});
  }
};


const getOrgs = async (
    meds: string[],
    orgs: string[],
    orgType: OrgType): Promise<OrgResponse> => {

  try {
    const response: any = await instance.post(`${env.ApiBaseUrl}/orgquery`, { meds, orgs, orgType });
    return response.data;
  } catch (error) {
    console.log({ error });
  }
};

// SPC BY MED
const getSpc = async (meds: string[]): Promise<SpcResponse[]> => {
  try {
    const response: any = await instance.post(`${env.ApiBaseUrl}/spcquery`, { meds });
    return response.data;
  } catch (error) {
    console.log({ error });
  }
};

// Adverse effect searches
const getAdvEffects =  async (
  meds: string[],
  startDate?: Date,
  endDate?: Date
  ): Promise<SearchTermsResponse[]> => {
  try {
    const response: any = await instance.post(`${env.ApiBaseUrl}/adveffectsquery`, { meds, startDate, endDate });
    return response.data;
  } catch (error) {
    console.log({ error });
  }
};

// Daily visits for front page
const getDailyVisits = async (
  meds: string[],
  startDate: Date,
  endDate: Date,
  orgs?: string[],
  ):
  Promise<DailyVisitsResponse[]> => {

  try {

    const response: any = await instance.post(
      `${env.ApiBaseUrl}/meddailyvisits`,
      { meds, startDate, endDate, orgs }
    );

    return response.data;

  } catch (error) {
    console.log({ error });
  }
};

// Front page insights
const getFrontpageInsights = async (
  meds: string[],
  week: number,
  year: number)
  : Promise<FrontpageInsightsResponse[]> => {

    try {

      const response: any = await instance.post(
        `${env.ApiBaseUrl}/frontpage_insights`,
        { meds, week, year}
      );

      return response.data;

    } catch (error) {
      console.log({ error });
    }
};

// Notification settings
const getNotificationSettings = async()
  : Promise<NotificationSettingsResponse[]> => {

    try {

      const response: any = await instance.get(
        `${env.ApiBaseUrl}/listnotifications`
      );
  
      return response.data;
  
    } catch (error) {
      console.log({ error });
    }
  };


const getAdGroups = async(): Promise<ADGroupResponse[]> => {
  try {

    const response: any = await instance.get(
      `${env.ApiBaseUrl}/getadgroups`
    );

    return response.data;

  } catch (error) {
    console.log({ error });
  }
};

const addNotification = async(
  ad_group: string,
  window_days: number,
  threshold_visits: number,
  medication: string,
  recipients: string[],
  organization_type: string
): Promise<number> => {
  
  try {
    const response: any = await instance.post(
      `${env.ApiBaseUrl}/addnotification`,
      {
        ad_group,
        window_days,
        threshold_visits,
        medication,
        recipients,
        organization_type
      }
    );

    return response.data[0];

  } catch (error) {
    console.log({ error });
  }
};

const addNotificationOrganizations = async(
  notification_id: number,
  organizations: string[]
): Promise<boolean> => {
  
  try {
    const response: any = await instance.post(
      `${env.ApiBaseUrl}/addnotificationorgs`,
      {
        notification_id,
        organizations
      }
    );

    return true;

  } catch (error) {
    console.log({ error });
    return false;
  }
};

const updateNotification = async(
  notification_id: number,
  window_days: number,
  threshold_visits: number,
  medication: string,
  recipients: string[],
  organization_type: string
): Promise<boolean> => {
  
  try {
    const response: any = await instance.post(
      `${env.ApiBaseUrl}/update_notification`,
      {
        notification_id,
        window_days,
        threshold_visits,
        medication,
        recipients,
        organization_type
      }
    );

    return true;

  } catch (error) {
    console.log({ error });
    return false;
  }
};

const updateNotificationOrgs = async(
  notification_id: number,
  organizations: string[]
): Promise<boolean> => {
  
  try {
    const response: any = await instance.post(
      `${env.ApiBaseUrl}/update_notification_orgs`,
      {
        notification_id,
        organizations
      }
    );

    return true;

  } catch (error) {
    console.log({ error });
    return false;
  }
};

const deleteNotification = async(
  notification_id: number,
): Promise<boolean> => {
  
  try {
    const response: any = await instance.post(
      `${env.ApiBaseUrl}/delete_notification`,
      {
        notification_id
      }
    );

    return true;

  } catch (error) {
    console.log({ error });
    return false;
  }
};

// Fetches stored selected active AD group, if any
const getActiveAdGroup = async(): Promise<ADGroupResponse> => {
  try {

    const response: any = await instance.get(
      `${env.ApiBaseUrl}/getactivegroup`
    );

    return response.data;

  } catch (error) {
    console.log({ error });
  }
};

const initializeActiveAdGroup = async(): Promise<ADGroupResponse> => {
  try {

    const response: any = await instance.post(
      `${env.ApiBaseUrl}/initializeactivegroup`
    );

    const activeGroupResponse = getActiveAdGroup();

    return activeGroupResponse;

  } catch (error) {
    console.log({ error });
  }
};

const updateActiveAdGroup = async(
  old_active_group_id: string,
  new_active_group_id: string
): Promise<boolean> => {
  try {
    const response: any = await instance.post(
      `${env.ApiBaseUrl}/set_active_group`,
      {
        old_active_group_id,
        new_active_group_id
      }
    );

    return true;

  } catch (error) {
    console.log({ error });
    return false;
  }
};

export { 
  loginWithCode,
  login,
  getSpc,
  getMeds,
  getOrgs,
  getMedNames,
  getOrgNames,
  getAdvEffects,
  getDailyVisits,
  getFrontpageInsights,
  getNotificationSettings,
  addNotification,
  getAdGroups,
  updateActiveAdGroup,
  addNotificationOrganizations,
  updateNotification,
  updateNotificationOrgs,
  deleteNotification,
  getActiveAdGroup,
  initializeActiveAdGroup
};
