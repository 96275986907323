import env from '../config';
import { HealthcareDistrictVisits } from '../types/types';

const getToken = (): string => {
  return localStorage.getItem('token');
};

const isAuthenticated = (): boolean => {
  const token = localStorage.getItem('token');
  let isValidToken = false;

  const parseJwt = (token: string) => {
    try {
      return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
      return null;
    }
  };

  const decodedJwt = token ? parseJwt(token) : null;
  if (!decodedJwt) {
    return false;
  }

  if (decodedJwt.exp * 1000 < Date.now()) {
    isValidToken = false;
  } else {
    isValidToken = true;
  }
  return isValidToken;
};

const saveToken = (token: string): void => {
  localStorage.setItem('token', token);
};

const logout = (): void => {
  localStorage.removeItem('token');
  window.location.href = `${env.FrontendBaseUlr}/login`;
};

const getCurrentDay = (): string => {
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, '0');
  const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  const yyyy = today.getFullYear();

  return yyyy + '-' + mm + '-' + dd;
};

const formatWeekRangeTickText = (date: string): string => {
  const startOfWeek: Date = new Date(date);

  const endOfWeek = new Date(date);
  endOfWeek.setDate(endOfWeek.getDate() + 6);

  let tickText: string;

  if (startOfWeek.getDate() > endOfWeek.getDate()) {
    // Month changes here
    if (startOfWeek.getMonth() === 11) {
      // Year changes here
      tickText =
        startOfWeek.getDate() +
        '/' +
        (startOfWeek.getMonth() + 1) +
        '/' +
        startOfWeek.getFullYear().toString() +
        '-' +
        endOfWeek.getDate() +
        '/' +
        (endOfWeek.getMonth() + 1) +
        '/' +
        endOfWeek.getFullYear().toString();
    } else {
      // Same year, different month
      tickText =
        startOfWeek.getDate() +
        '/' +
        (startOfWeek.getMonth() + 1) +
        '-' +
        endOfWeek.getDate() +
        '/' +
        (endOfWeek.getMonth() + 1) +
        '/' +
        endOfWeek.getFullYear().toString().slice(2);
    }
  } else {
    // Week is inside a month
    tickText =
      startOfWeek.getDate() +
      '-' +
      endOfWeek.getDate() +
      '/' +
      (startOfWeek.getMonth() + 1) +
      '/' +
      startOfWeek.getFullYear().toString().slice(2);
  }

  return tickText;
};

const sumOrganizationVisits = (visits: HealthcareDistrictVisits[]) => {
  return visits.map(({ organization, visits }) => {
    return {
      organization: organization,
      totalVisits: visits.reduce(
        (previousValue, visitArray) => previousValue + visitArray.visits,
        0
      ),
    };
  });
};

const sumAllOrganizationVisits = (visits: HealthcareDistrictVisits[]) => {
  const visitsByOrg = sumOrganizationVisits(visits);
  return visitsByOrg.reduce((previousValue, visits) => previousValue + visits.totalVisits, 0);
};

export {
  getToken,
  logout,
  isAuthenticated,
  saveToken,
  getCurrentDay,
  formatWeekRangeTickText,
  sumOrganizationVisits,
  sumAllOrganizationVisits,
};
