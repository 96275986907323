import React, { useState, useEffect } from 'react';
import Error from '../routes/Error';

export const Spinner = (): JSX.Element => {
  const [isError, setIsError] = useState<boolean>(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsError(true);
    }, 100000);
    return () => clearTimeout(timer);
  }, []);
  return <div>{isError ? <Error /> : <div className='spinner'></div>}</div>;
};
