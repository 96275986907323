import { Route, Redirect } from 'react-router-dom';
import React from 'react';

interface Props {
  children: any;
  path: string;
  isAuthenticated: boolean;
}

const PrivateRoute: React.FC<Props> = ({
  children,
  isAuthenticated,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
