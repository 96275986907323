import React, { useState } from 'react';
import logo from '../assets/images/logo.svg';
import hamburger from '../assets/images/hamburger.svg';
import { logout } from '../utils/utils';
import { Link } from 'react-router-dom';

const Navbar = (): JSX.Element => {
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  const handleLogout = () => {
    logout();
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <div className='navbar'>
      <img src={logo} alt='DataKit logo' className='logo' />
      <div className='nav-buttons'>
        <Link to='/' className='nav-button'>
          Home
        </Link>
        <Link to='/search' className='nav-button'>
          Search for medication
        </Link>
        <div className='dropdown-container' style={{display:"block"}}>
          <img
            src={hamburger}
            className='hamburger-icon'
            alt='hamburger menu'
            onClick={toggleDropdown}
          />
          <div className={`dropdown-menu ${isDropdownOpen ? 'open' : ''}`}>
            <div><Link to='/notifications' className='nav-button'>Notification Settings</Link></div>
            <div><Link to='/organizations' className='nav-button'>Organization Settings</Link></div>
            <div><button className='nav-button' onClick={handleLogout}>Log out</button></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
