import './assets/css/App.scss';
import { lazy, Suspense, useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
// import ProtectedRoutes from './routes/ProtectedRoutes'; //Authenticated routes
import PublicRoute from './routes/PublicRoute';
import PrivateRoute from './routes/PrivateRoute';
import { isAuthenticated } from './utils/utils';
import Navbar from './components/Navbar';
import { Spinner } from './components/Spinner';
import React from 'react';
import {getActiveAdGroup, initializeActiveAdGroup} from './services/apiService';
import { ADGroupResponse } from './types/types';

const LoginPage = lazy(() => import('./routes/LoginPage'));
const NotificationSettings = lazy(() => import('./routes/NotificationSettings'));
const OrganizationSettings = lazy(() => import('./routes/OrganizationSettings'));
const Views = lazy(() => import('./routes/Views'));
const LoginOk = lazy(() => import('./routes/LoginOk'));
const Error = lazy(() => import('./routes/Error'));

// const NoFoundComponent = lazy(() => import('./routes/NoFoundComponent'));

const UserContext = React.createContext(null);

export default function App (): JSX.Element {

  // Fetch active organization
  // Active organization is preserved between sessions
  // on database level.
  //
  // If it's the first session, Datakit internal organization
  // is preferred, otherwise organization is chosen randomly
  const [userOrganization, setUserOrganization] = useState<ADGroupResponse>();

  const initializeUser = async () => {

    const activeAdGroup = await getActiveAdGroup().then(
      function(activeGroupResponse) {
        if (!activeGroupResponse){
          initializeActiveAdGroup();
  
          return activeGroupResponse;
        }
      }

    ).then(function(activeGroupResponse) {
      if(!activeGroupResponse){
        return getActiveAdGroup();
      } else {
        return activeGroupResponse;
      }
    }) ;

    setUserOrganization(activeAdGroup);
    return;
  
  };
  


  useEffect(() => {
    initializeUser();
  }, []);

  return (
    <Router>
      <Suspense fallback={<Spinner />}>
        {isAuthenticated() && <Navbar />}
        <Switch>
          <PublicRoute path='/login' isAuthenticated={isAuthenticated()}>
            <LoginPage />
          </PublicRoute>
          <PublicRoute path='/loginok' isAuthenticated={isAuthenticated()}>
            <LoginOk />
          </PublicRoute>
          <PrivateRoute path='/error' isAuthenticated={isAuthenticated()}>
            <Error />
          </PrivateRoute>
          <PrivateRoute path='/notifications' isAuthenticated={isAuthenticated()}>
            <NotificationSettings />
          </PrivateRoute>
          <PrivateRoute path='/organizations' isAuthenticated={isAuthenticated()}>
            <OrganizationSettings />
          </PrivateRoute>
          <PrivateRoute path='/' isAuthenticated={isAuthenticated()}>
            <Views />
          </PrivateRoute>
          {/* <Route path="*">
            {<div>Sivua ei löydy</div>}
          </Route> */}
        </Switch>
      </Suspense>
    </Router>
  );
}
